// depends on /prodo-plugins/accessibility.js

var initToolbar = function () {
    var $accessibilityBar = $('#toolbox'),
        $toolboxToggleButton = $('.js-toolbox-toggler');

    // ============================================================================================================================== //
    // ======================================================= ToolBar UI =========================================================== //
    // ============================================================================================================================== //

    var openToolBoxMenu = function ($menu) {
        if ($menu.length) {
            $menu.addClass('open');
        }
    }

    var closeToolBoxMenu = function ($menu) {
        if ($menu.length) {
            $menu.removeClass('open');
        }
    };

    var toggleToolBoxMenu = function ($menu) {
        if ($menu.length) {
            if ($menu.hasClass('open')) {
                $menu.removeClass('open');
            } else {
                $menu.addClass('open');
            }
        }
    };

    var openToolBox = function () {
        $toolboxToggleButton.find('span').text('Show');
        $toolboxToggleButton.removeClass('active');
        $accessibilityBar.addClass('active');
        createCookie("hideToolbar", 'true', 0, 0, 182);
    }

    var closeToolBox = function () {
        $toolboxToggleButton.find('span').text('Hide');
        $toolboxToggleButton.addClass('active');
        $accessibilityBar.removeClass('active');
        deleteCookie("hideToolbar");
    }

    // var setToolboxInitialState = function () {
    //
    //     var hideToolbarCookie = getCookie('hideToolbar');
    //
    //     if (!hideToolbarCookie) {
    //         setTimeout(function () {
    //             openToolBox();
    //             $toolboxToggleButton.addClass('active');
    //         }, 301);
    //     }
    //
    // }

    // UI Bindings

    //setToolboxInitialState();

    // $('.open_toolbox_menu').on('click', function () {
    //     var targetMenuId = $(this).attr('data-target-menu'),
    //         $targetMenu = $('#' + targetMenuId);
    //     openToolBoxMenu($targetMenu);
    // });
    //
    // $('.close_toolbox_menu').on('click', function () {
    //     var targetMenuId = $(this).attr('data-target-menu'),
    //     $targetMenu = $('#' + targetMenuId);
    //     closeToolBoxMenu($targetMenu);
    // });

    $toolboxToggleButton.on('click', function () {

      if ($(this).hasClass('active')) {
        openToolBox();
      } else {
        closeToolBox();
      }

    });

    // ============================================================================================================================== //
    // =================================================== Contrast Functions ======================================================= //
    // ============================================================================================================================== //

    // UI Bindings

    $(".contrast_select").find('button').click(function () {
        changeContrastTheme($(this).attr('id'));
    });

    // ============================================================================================================================== //
    // =================================================== Font Size Functions ====================================================== //
    // ============================================================================================================================== //

    // UI Bindings

    $('.toggle_text_size').on('click', function () {
        var changeType = $(this).attr('data-change-type');
        changeFontSize(changeType);
    });

    // ============================================================================================================================== //
    // =================================================== Back to top Function ===================================================== //
    // ============================================================================================================================== //

    // UI Bindings

    $('#back_to_top').on('click', function () {
        scrollToTopOfPage();
    });
};

var initSlideAway = function () {

  var sa_trigger = $('.js-slide-away-trigger'),
      sa_element = $('.js-slide-away-element');

      sa_trigger.on('click', function(){
        sa_element.slideUp();
      });

}

// depends on /vendor/slick/slick.js

var prevArrowHtml = '<button class="slide-prev-button slide-nav"><i></i><span class="u--hide-text">Previous slide</span></button>',
    nextArrowHtml = '<button class="slide-next-button slide-nav"><i></i><span class="u--hide-text">Next slide</span></button>';



var initSliders = function () {
    initSimpleSlider();
    initSimpleSliderWithDots();
    initSimpleSliderGallery();
}

var initSimpleSlider = function () {

  var simple_slider = $('.js-simple-slider');

  if (simple_slider.length)
  {

    $('.js-simple-slider').slick({
      arrows: true,
      prevArrow: prevArrowHtml,
      nextArrow: nextArrowHtml
    });

  }

}

var initSimpleSliderWithDots = function () {

  var slider_with_dots = $('.js-simple-slider-with-dots');

  if (slider_with_dots.length)
  {

    $('.js-simple-slider-with-dots').slick({
        dots: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
    });

  }

}

var initSimpleSliderGallery = function () {

  var ssg_slides_to_show = 2;

  if (isMedium()) {
    ssg_slides_to_show = 3;
  }
  else if (isLarge()) {
    ssg_slides_to_show = 4;
  }

  $('.js-simple-slider-gallery__slides').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: prevArrowHtml,
    nextArrow: nextArrowHtml,
    fade: true,
    asNavFor: '.js-simple-slider-gallery__thumbs'
  });
  $('.js-simple-slider-gallery__thumbs').slick({
    slidesToShow: ssg_slides_to_show,
    slidesToScroll: 1,
    arrows: false,
    asNavFor: '.js-simple-slider-gallery__slides',
    centerMode: true,
    focusOnSelect: true
  });

}

// Standard foundation call

//$(document).foundation();

// Standard foundation call
$(document).foundation({
    equalizer: {
        // Specify if Equalizer should make elements equal height once they become stacked.
        equalize_on_stack: true
    }
});

//=========  DOCUMENT READY CALLS  =========

$(document).ready(function () {

    initPolyfills();
    initRTEImages();
    initSliders();
    initSelectric();
    initToggler();
    initSlideAway();
    initToolbar();
    initSiteSearch();
    initTabsToAccordion();
    initScrollTable();
    initJsonGoogleMap();
    initInternalNav();
    initPrintThis();
    initPrintThisAccordion();
    initAccessibleMenu();

    $('.tabs .tab-title > a').on('click', function () {

        window.setTimeout(function () {
            $(document).foundation('equalizer', 'reflow');
            initJsonGoogleMap(true);
        }, 100);

    });


    if ($('.js-videowrapper').length) {
        $('.js-videowrapper').find('iframe').attr('title', 'embedded video');
    }

    const observer = lozad(); // lazy loads elements with default selector as '.lozad'
    observer.observe();

    $('select').selectric();
});

//Printing entire page
var initPrintThis = function () {
    $('[data-print-trigger]').on('click', function () {
        $('[data-print-this]').printThis({
            debug: true,
            printDelay: 300
        });
    });
};

//Printing specific accordion page
var initPrintThisAccordion = function () {
    $('[data-print-trigger-accordion]').on('click', function () {
        /*Gets css id of relevant accordion information data-print-id uses accordion's id and accordion
         * content id, so should always be unique, even if other accordions are on the same page.*/
        var accordion_content_id = $(this).attr('data-print-id');

        //Combine unique accordionContent with normal page info
        var accorionContent = $('[id=' + accordion_content_id + ']');

        var pageContent = $('[data-print-this-accordion]');

        var elementsToPrint = accorionContent.add(pageContent);

        //Print it all
        elementsToPrint.printThis();
    });
};
var initSiteSearch = function () {

  var ss_trigger = $('.js-ss-trigger'),
      ss_search_bar = $('.js-search-bar'),
      ss_search_input = $('.site-search__element--input'),
      ss_close = $('.js-ss-close');

      ss_trigger.on('click', function(){

        ss_search_bar.toggleClass('active');
        $(document).foundation('reflow');

        setTimeout(function(){
    			ss_search_input.focus();
    		}, 100);

      });

      ss_close.on('click', function(){
        ss_search_bar.removeClass('active');
      });
        
      $('#header-search-form-form').submit(function () {

          if ($('#input-search').val() == "") {
              return false;
          }

          // everything ok, continue submission
      });

      $('#mobile-search-form-form').submit(function () {

          if ($('#mobile-input-search').val() == "") {
              return false;
          }

          // everything ok, continue submission
      });

  $('.js-ss-mobile-trigger').click(function()
  {
    $('#site_search__wrapper').addClass('active');
  });

  $('.site_search__close_button').click(function()
  {
    $('#site_search__wrapper').removeClass('active');
  });

}

var initTabsToAccordion = function () {

    $('.tab-title > a').on('click', function () {

        var link = $(this);
        var content = link.parents('.tabs-row').find('.content').removeClass('active');

        setTimeout(function () {
          $(document).foundation('equalizer', 'reflow');
        }, 10);
         
    });

    $('.accordion-navigation > a').on('click', function () {
        // scroll into view if on mobile
        
        var mediumBreakpoint = 600;
        if ($(window).width() <= mediumBreakpoint) {
            var $clickedAccordionHeading = $(this);

            setTimeout(function () {
                $("html, body").animate({ scrollTop: $clickedAccordionHeading.offset().top });
            }, 300);
        }
    });

};


var initJsonGoogleMap = function (force) {
    /*Map*/

    var all_maps = $('.map_canvas');


    if (all_maps.length) {
        var styles = [{"featureType":"landscape.man_made","elementType":"geometry","stylers":[{"color":"#f7f1df"}]},{"featureType":"landscape.natural","elementType":"geometry","stylers":[{"color":"#d0e3b4"}]},{"featureType":"landscape.natural.terrain","elementType":"geometry","stylers":[{"visibility":"off"}]},{"featureType":"poi","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"poi.business","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"poi.medical","elementType":"geometry","stylers":[{"color":"#fbd3da"}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#bde6ab"}]},{"featureType":"road","elementType":"geometry.stroke","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffe15f"}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#efd151"}]},{"featureType":"road.arterial","elementType":"geometry.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"road.local","elementType":"geometry.fill","stylers":[{"color":"black"}]},{ "featureType": "road.local", "elementType": "labels.text.fill", "stylers": [{ "visibility": "on" }] },{"featureType":"transit.station.airport","elementType":"geometry.fill","stylers":[{"color":"#cfb2db"}]},{"featureType":"water","elementType":"geometry","stylers":[{"color":"#a2daf2"}]}];

        var imgPath = '/images/icons/map-pins/';
        var image = imgPath + 'default.png';

        var i = 0;

        function addMarkers(map, map_element) {

          var markers = [],
          bounds = new google.maps.LatLngBounds();

            var isThereMarkers = false;

            var jsonSrc = $(map_element).data("json");
            var infoWindow = new google.maps.InfoWindow({});

            for (var i = 0; i < jsonSrc.length; i++) {
                var location = jsonSrc[i];
                var contentString = location.Title;

                var marker = new google.maps.Marker({
                    map: map,
                    icon: image,
                    position: new google.maps.LatLng(location.Latitude, location.Longitude),
                    title: contentString,
                    bubbleContent: location.BubbleContent,
                    link: location.Link,
                    buttonText: location.ButtonText,
                    markerId: location.Id,
                    area: location.Area,
                    status: typeof(location.DevelopmentStatus) == 'undefined' ? location.Status : location.DevelopmentStatus,
                });

                bounds.extend(marker.position);

                marker.setMap(map);
                markers.push(marker);
            }

            if (markers.length > 0) {
                //map.initialZoom = true;
                map.fitBounds(bounds);
            }
        }

        function initialize() {
            var latlng = new google.maps.LatLng(53.386195, -3.037148);
            var mapOptions = {
                zoom: 15,
                minZoom: 10,
                maxZoom: 15,
                scrollwheel: false,
                center: latlng,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                mapTypeControl: false,
                panControlOptions: {
                    position: google.maps.ControlPosition.TOP_LEFT
                },
                zoomControlOptions: {
                    position: google.maps.ControlPosition.TOP_LEFT
                }
            }

            $.each(all_maps, function(index, map_element){

              if ($(map_element).hasClass('no-icons'))
              {
                  mapOptions.zoomControl = false;
                  mapOptions.scaleControl = false;
                  mapOptions.mapTypeControl = false;
                  mapOptions.disableDefaultUI = true;
              }

              // alert(mapOptions.mapTypeControl);

              var map = new google.maps.Map(map_element, mapOptions);

              map.setOptions({ styles: styles });

              addMarkers(map, map_element);

              google.maps.event.addDomListener(window, "resize", function () {
                  var center = map.getCenter();
                  google.maps.event.trigger(map, "resize");
                  map.setCenter(center);
              });

              google.maps.event.addListener(map, 'zoom_changed', function () {
                  zoomChangeBoundsListener =
                      google.maps.event.addListener(map, 'bounds_changed', function (event) {
                          if (this.getZoom() > 15 && this.initialZoom == true) {
                              // this.setZoom(15);
                              // this.initialZoom = false;
                          }
                          google.maps.event.removeListener(zoomChangeBoundsListener);
                      });
              });

            });


        }

        google.maps.event.addDomListener(window, 'load', initialize);

        if(typeof(force)!== 'undefined') {
          initialize();
        }

    }
};

var initAccessibleMenu = function () {
    
    var menuItems = document.querySelectorAll('li.has-submenu');
    
    Array.prototype.forEach.call(menuItems, function(el, i){
        
    	var activatingA = el.querySelector('a');
    	var btn = '<button class="primary-nav__toggle"><span><span class="visuallyhidden">show submenu for “' + activatingA.text + '”</span></span></button>';
    	activatingA.insertAdjacentHTML('afterend', btn);

    	el.querySelector('button').addEventListener("click",  function(event){
    		if (this.parentNode.className == "primary-nav__item has-submenu") {
    			this.parentNode.className = "primary-nav__item has-submenu open";
    			this.parentNode.querySelector('a').setAttribute('aria-expanded', "true");
    			this.parentNode.querySelector('button').setAttribute('aria-expanded', "true");
    		} else {
    			this.parentNode.className = "primary-nav__item has-submenu";
    			this.parentNode.querySelector('a').setAttribute('aria-expanded', "false");
    			this.parentNode.querySelector('button').setAttribute('aria-expanded', "false");
    		}
    		event.preventDefault();
    	});
    
});

}
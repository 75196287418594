var initToggler = function () {

    $('.toggler__nav button').click(function() {
    	var data = $(this).data('toggler-find');

    	if (!$(this).hasClass('toggler__nav-control--active')) {
    		$('.toggler__nav button').removeClass('toggler__nav-control--active');
    		$(this).addClass('toggler__nav-control--active');
    	}

      $(".toggler__zone").hide();
    	$(".toggler").find("[data-toggler='" + data + "']").show();
      $(document).foundation('reflow'); 

    });

    $('.st-menu__link.st-menu__link--children + .st-menu__toggler--control').click(function()
    {
        var parent = $(this).closest('.st-menu__link-item');
        parent.find('.st-menu__link--children').toggleClass('active');
        parent.find('.primary-nav__links').slideToggle();
    });

};

var initInternalNav = function() {

    var $internalNav = $('.js-internal-nav'),
    $internalNav_trigger = $('.js-internal-nav__trigger');


    if ($internalNav.length) {

    	$internalNav_trigger.click(function() {

    		$(this).parents('.accordion-navigation').toggleClass('active');
    		$(this).next('.accordion__sub-nav').slideToggle();

    	});

    }

}
